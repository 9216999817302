<!--  -->
<template>
  <div class="">
    <el-card class="box-card">
      <div style="display: flex; align-items: center" v-if="!group_id">
        <div class="title">搜索班课</div>

        <el-input
          style="width: 30%; border-radius: 40px"
          placeholder="请输入班课名称"
          v-model="courseName"
          @change="search"
          class="input-with-select"
          :clearable="true"
          @keyup.enter.native="search"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          ></el-button>
        </el-input>
      </div>

      <div
        class="table"
        :style="{ marginTop: group_id ? '0' : '20px' }"
        v-loading="loading"
      >
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @sort-change="courseSortChange"
          :row-key="(row) => row.id"
        >
          <el-table-column
            label="班课名称"
            prop="course_name"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span class="ProblemList-name">{{
                scope.row.course_name
              }}</span></template
            >
          </el-table-column>
          <el-table-column
            label="创建人"
            prop="nickname"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.nickname }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="开班时间"
            prop="course_start_time"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">{{
              scope.row.course_start_date
            }}</template>
          </el-table-column>
          <!-- <el-table-column label="数量" align="center" prop="matNumber">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.matNumber"
                @change="changedMatNum(scope.$index, scope.row, $event)"
                placeholder="请输入数量"
              >
              </el-input>
            </template>
          </el-table-column> -->
          <el-table-column label="应出勤日期" align="center" width="260">
            <template slot-scope="scope">
              <!-- <el-input
                v-model="scope.row.dateValue"
                placeholder="请输入应出勤日期"
                @change="changeDate"
                style="width: 100%"
                class="inputClass"
              ></el-input> -->
              <el-date-picker
                v-model="scope.row.dateValue"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="应出勤时长h" align="center">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.hourValue"
                placeholder="请输入应出勤时长h"
                @change="changeHour"
                style="width: 100%"
                class="inputClass"
              ></el-input
            ></template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >录入学生考勤</el-button
              >
              <el-button
                @click="checkHistory(scope.row)"
                type="text"
                size="small"
                >查看历史考勤</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="margin-top: 20px">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 录入学生考勤 -->
    <el-dialog
      title="录入学生考勤"
      width="70%"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="gridData1" height="500">
        <el-table-column
          property="nickname"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          property="sign_plan_date"
          label="应出勤日期"
          align="center"
        ></el-table-column>
        <el-table-column
          property="p_time_str"
          label="应出勤时长h"
          align="center"
        ></el-table-column>
        <el-table-column
          property="time_str"
          label="实际出勤时长h"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.time_str"
              placeholder="请输入实际出勤时长h"
              @change="
                changehourValue(scope.$index, scope.row.id, scope.row.time_str)
              "
              style="width: 100%"
              class="inputClass"
            ></el-input
          ></template>
        </el-table-column>
        <el-table-column property="remark" label="说明" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remark"
              placeholder="请输入说明"
              @change="
                changeExplain(scope.$index, scope.row.id, scope.row.remark)
              "
              style="width: 100%"
              class="inputClass"
            ></el-input
          ></template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>

      <div
        class="pagination"
        style="
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total1"
          @current-change="currentChange1"
          :page-size="pageSize1"
          :current-page="page1"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 查看历史考勤 -->
    <el-dialog
      title="查看历史考勤"
      width="70%"
      :visible.sync="dialogTableVisible2"
    >
      <div>
        <el-button type="primary" size="small" @click="exportExcel"
          >导出表格</el-button
        >
      </div>
      <el-table
        v-loading="hisLoading"
        :data="gridData"
        @sort-change="sortChange"
        :default-sort="{ prop: 'row_num', order: 'ascending' }"
      >
        <el-table-column
          prop="row_num"
          label="出勤排名"
          width="120"
          align="center"
          sortable="custom"
        ></el-table-column>
        <el-table-column
          prop="id"
          width="120"
          label="序号"
          align="center"
          sortable="custom"
        ></el-table-column>

        <el-table-column
          prop="show_name"
          label="学校-姓名"
          align="center"
          width="180"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.show_name }}</span></template
          >
        </el-table-column>

        <el-table-column
          v-for="(v, i) in headers"
          :key="i"
          :prop="v"
          :label="v"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.sign_list[i].time_str">
              {{ scope.row.sign_list[i]?.time_str }}h
            </div>
            <div v-else>/</div>
          </template>
        </el-table-column>

        <el-table-column prop="rate" label="出勤率" align="center">
          <template slot-scope="scope">
            {{ scope.row.sign_rate + "%" }}
          </template></el-table-column
        >
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogTableVisible2 = false"
          >确 定</el-button
        >
      </span>

      <div
        class="pagination"
        style="
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="hisTotal"
          @current-change="currentChange1"
          @size-change="handleSizeChange1"
          :page-size="pageSize1"
          :current-page="page1"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    group_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    //这里存放数据
    return {
      courseName: "",
      loading: false,
      hisLoading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      hisTotal: 0,
      page1: 1,
      pageSize1: 50,
      total1: 0,
      tableData: [],
      gridData: [],
      gridData1: [],
      headers: [
        // { label: "2022-10-23 3h:XX-XX:XX", prop: "course_id" },
        // { label: "2022-10-23 3h:XX-XX:XX", prop: "course_id" },
        // { label: "2022-10-23 3h:XX-XX:XX", prop: "course_id" },
      ],
      keysData: [],
      dialogTableVisible: false,
      dialogTableVisible2: false,
      courseId: 0,
      sort: "row_num-asc",
      courseSort: "",
      id: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getClassNowList();
  },
  //方法集合
  methods: {
    // 导出表格
    exportExcel() {
      console.log(XLSX, "XLSX");
      // 假设你有一个表格数据的数组
      console.log(this.gridData, "this.gridData.list");
      const data = this.gridData.map((item) => {
        // 这里假设你的数据是一个对象，你需要将其转换为数组
        // 数组的第一个元素通常是标题（列名）

        return [
          item.row_num,
          item.id,
          item.show_name,
          ...item.sign_list.map((v) => v.time_str),
          item.sign_rate,
        ];
      });
      console.log(data, "data");
      let titleArr= ["出勤排名", "出勤序号", "学校-姓名", ...this.headers, "出勤率"]
      // return
      let data2 = [
        ["出勤排名", "出勤序号", "学校-姓名", ...this.headers, "出勤率"],
        ...data,
      ];
      console.log(data2, "data2data2");
      // return

      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(data2);
      
      let wpxArr=[]
      titleArr.forEach(v=>{
        wpxArr.push({wpx: 120})
      })
    console.log(wpxArr,"wpxArr")
      worksheet["!cols"] = wpxArr
      // [
      //   {
      //     wpx: 100,
      //   },
      //   {
      //     wpx: 100,
      //   },
      //   {
      //     wpx: 100,
      //   },
      //   {
      //     wpx: 100,
      //   },
      //   {
      //     wpx: 100,
      //   },
      //   {
      //     wpx: 100,
      //   },
      // ];
      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // 使用blob和saveAs下载文件
      const dataBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(dataBlob, "考勤列表.xlsx");

      // window.location.href =
      //   (location.baseURL || "") +
      //   `/api/v1_0_0.question_other/rank_log_down?other_id=${
      //     this.other_id
      //   }&course_id=${this.course_id}&group_id=${
      //     this.group_id
      //   }&user_id=${localStorage.getItem("user_id")}&user_token=` +
      //   encodeURIComponent(localStorage.getItem("user_token"));
    },
    // 获取当前班课列表
    getClassNowList() {
      // page: 1,
      // pageSize: 50,
      // total: 0,
      this.loading = true;
      this.$http
        .get(
          `/xapi/user.group/courseStaticticNowList?page=` +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&keyword=" +
            this.courseName +
            "&group_id=" +
            Number(this.group_id) +
            "&learn_mode=2" +
            "&sort=" +
            this.courseSort
        )
        .then((res) => {
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    search() {
      this.getClassNowList();
    },
    // 应出勤日期
    changeDate(e) {
      console.log(e, "eeeeeeee");
    },
    // 应出勤时长
    changeHour(e) {
      console.log(e, "时长");
    },
    // 实际出勤时长
    changehourValue(index, id, e) {
      console.log(index, "index");
      console.log(id, "iddddddddd");
      console.log(e, "实际出勤时长");
      let a = this.gridData1[index];
      console.log(a, "aaaaaaaaaaa");
    },
    // 说明
    changeExplain(index, id, e) {
      console.log(e, "说明");
      console.log(index, "index");
      console.log(id, "iddddddddd");
      let a = this.gridData1[index];
      console.log(a, "aaaaaaaaaaa");
    },
    // 确定
    submit() {
      const newData = this.gridData1.map((v) => {
        return {
          user_id: v.id,
          p_time_str: v.p_time_str,
          course_id: v.course_id,
          sign_plan_date: v.sign_plan_date,
          time_str: v.time_str,
          remark: v.remark,
        };
      });
      let data = {
        items: newData,
      };
      console.log(data, "dataaaaaa");
      this.$http
        .post("/xapi/user.index/userSign", data)
        .then((res) => {
          console.log(res, "录入考勤");
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.dialogTableVisible = false;
          }
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 录入学生考勤
    handleClick(e) {
      if (!e.dateValue) return this.$message.error("请选择应出勤日期！");
      if (!e.hourValue) return this.$message.error("请输入应出勤时长！");
      console.log(e, "eeeeee");
      this.courseId = e.id;

      this.$http
        // .get(
        //   `xapi/course.course/studentList?course_id=` +
        //     e.id +
        //     "&page=" +
        //     this.page +
        //     "&pageSize=" +
        //     this.pageSize
        // )3-6收费班课-李01
        .get(
          `xapi/course.course/studentAllList?course_id=` +
            e.id +
            "&page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&status=1"
        )
        .then((res) => {
          this.dialogTableVisible = true;
          this.gridData1 = res.data.data;
          if (this.gridData1 && this.gridData1.length > 0) {
            this.gridData1.forEach((v) => {
              v.sign_plan_date = e.dateValue;
              v.p_time_str = e.hourValue;
              v.course_id = this.courseId;
              this.$set(v, "time_str", e.hourValue);
              // v.time_str=e.hourValue
            });
          }

          console.log(this.gridData1, "this.gridData1");
          // this.headers = res.data.date_list;
          this.total1 = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 查看历史考勤
    checkHistory(e) {
      console.log(e, "eeee");
      this.hisLoading = true;
      // this.sort='row_num-asc'
      // retu
      if (e) {
        this.id = e.id;
      }
      this.$http
        .get(
          "/xapi/user.index/signHisList?page=" +
            this.page1 +
            "&pageSize=" +
            this.pageSize1 +
            "&course_id=" +
            this.id +
            "&sort=" +
            this.sort
        )
        .then((res) => {
          this.hisLoading = false;

          this.dialogTableVisible2 = true;
          console.log(res, "查看历史考勤");
          this.gridData = res.data.list;
          this.headers = res.data.date_list;
          this.hisTotal = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 班课排序
    courseSortChange(column) {
      console.log(column, "column");
      const orderData =
        column.order == "descending"
          ? "desc"
          : column.order == "ascending"
          ? "asc"
          : null;
      console.log(orderData, "orderData");
      this.courseSort = column.prop + "-" + orderData;
      console.log(this.courseSort, "this.sort");
      this.getClassNowList();
    },
    // 查看历史考勤排序
    sortChange(column) {
      console.log(column, "column");
      const orderData =
        column.order == "descending"
          ? "desc"
          : column.order == "ascending"
          ? "asc"
          : null;
      console.log(orderData, "orderData");
      this.sort = column.prop + "-" + orderData;
      console.log(this.sort, "this.sort");
      this.checkHistory();
    },
    // changedMatNum(index, row, even) {
    //   console.log("-------数量------");
    //   console.log("index：" + index);
    //   console.log("row：" + row);
    //   console.log("even：" + even);
    //   // console.log(even.currentTarget.nextElementSibling);
    //   console.log("-------发送接口-------");

    //   //遍历 物料列表，根据index塞值
    //   // var matForm = this.tableData[index];
    //   // matForm.matNumber = event.target.value;

    //   // for (let key in matForm) {
    //   //   console.log(
    //   //     "当前行mat    " + "key:" + key + "    value:" + matForm[key]
    //   //   );
    //   // }
    // },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getClassNowList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getClassHistoryList();
    },
    currentChange1(e) {
      this.page1 = e;
      this.checkHistory();
    },
    handleSizeChange1(e) {
      this.pageSize1 = e;
      this.checkHistory();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
::v-deep .el-dialog {
  height: 84vh;
  overflow: auto;
}
.box-card {
  // margin-top: 20px;
  .title {
    margin-right: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
  }
  ::v-deep {
    .el-input__inner {
      border-radius: 40px 0 0 40px;
      height: 32px;
    }
    .el-table__row {
      .el-input__inner {
        border-radius: 4px !important;
        height: 32px;
      }
    }
    .el-input-group__append {
      border-radius: 0 40px 40px 0;
      background: #00957e;
      color: #ffffff;
    }
    .border-base {
      text-align: center;
      border-radius: 20px;
      padding: 10px;
      border: 1px solid #ccc;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>